import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getHashValueByHistory } from '../util'
import { IS_VALID_PROSPECT_ID } from '../constant'

function AuthRoute({ children }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (IS_VALID_PROSPECT_ID) {
            sessionStorage.setItem('referrer', document.referrer);
            if (document.referrer) {
                if (document.referrer.indexOf(process.env.REACT_APP_DOMAIN_NAME) === -1) {
                    window.location.href = '/error.html';
                    return;
                };
            };
        };

        if (window.location.search) {
            const searchVal = getHashValueByHistory();
            if (searchVal.length !== 18) {
                window.location.href = '/error.html';
            };
        } else {
            window.location.href = '/error.html';
        };

    }, []);

    return children;
};

export default AuthRoute;
