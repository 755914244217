import React from 'react';
import ReactDOM from 'react-dom/client';
import Routers from "./routers";
import {
    closePageBehaviorRecord, buildStayTimeParams,
    saveTextToFile, behaviorRecord,
    getHashValueByHistory
} from './util';

ReactDOM.createRoot(document.getElementById("root"))
    .render(<Routers />);

window._indexStayTime_ = 1;
window._submitStatyTime_ = 1;

const beforeunloadHandler = () => {
    const indexParams = {
        customerId: getHashValueByHistory(),
        sourceType: (document.referrer && "TS") || '',
        sourceId: '',
        id: sessionStorage.getItem('stayTimeId'),
        indexPageDwellTime: _indexStayTime_
    };

    const submitParams = {
        customerId: getHashValueByHistory(),
        sourceType: (document.referrer && "TS") || '',
        sourceId: '',
        id: sessionStorage.getItem('stayTimeId'),
        submitPageDwellTime: _submitStatyTime_
    };

    if (document && document.location
        && document.location.href.indexOf('index.html') !== -1) {
        closePageBehaviorRecord(indexParams);
    } else if (document && document.location
        && document.location.href.indexOf('submit.html') !== -1) {
        closePageBehaviorRecord(submitParams);
    };
};

window.addEventListener('beforeunload', beforeunloadHandler);

if (module.hot) {
    module.hot.accept();
};